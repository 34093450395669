export default [
  {
    path: "/app/servers",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Servidores",
      requiresAuth: true,
    },
    children: [
      //----------------------------Servers----------------------------\\
      {
        path: "function-servers",
        name: "servers.function-servers",
        component: () => import("../function-servers/views/List.vue"),
      },
      {
        path: "function-servers/create",
        name: "servers.function-servers.create",
        component: () => import("../function-servers/views/Create.vue"),
      },
      {
        path: "function-servers/show/:id",
        name: "servers.function-servers.show",
        component: () => import("../function-servers/views/Show.vue"),
      },
      {
        path: "function-servers/edit/:id",
        name: "servers.function-servers.edit",
        component: () => import("../function-servers/views/Edit.vue"),
      },

      //----------------------------report Teachers Subject Taught Class----------------------------\\

      {
        path: "teachers-subjects-taught-class",
        name: "servers.teachers-subjects-taught-class",
        component: () =>
          import("../teachers-subjects-taught-class/views/List.vue"),
      },
    ],
  },
];
