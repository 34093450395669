import axios from "axios";
import "regenerator-runtime/runtime";
import router from "@/router";

export const TOKEN_NAME = "bearer_token";

export function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}

function getSelectedYear() {
  return localStorage.getItem("selectedYear");
}

export function getAuthorization() {
  return `Bearer ${getToken()}`;
}

const http = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    const { status } = response;
    const { data } = response;

    if (response && status === 500) {
      $swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor. Entre em contato com o suporte!",
      });
    }

    if (response && status === 422) {
      if (data.errors) {
        Object.keys(data.errors).forEach((item) => {
          let field = data.errors[item];
          for (let i = 0; i < field.length; i++) {
            message += field[i] + "<br />";
          }
        });

        $swal.fire("Erro", message, "error");
      }
    }

    if (response && (status === 404 || status === 403)) {
      const { error } = data;

      $swal.fire({
        icon: "warning",
        title: "Erro!",
        text: error,
      });
    }

    if (response && status === 401) {
      const { error } = data;
      if (!error) {
        $swal
          .fire({
            icon: "warning",
            title: "Sessão expirada!",
            text: "Por favor, realize o login",
          })
          .then(() => {
            localStorage.removeItem(TOKEN_NAME);
            const originalRoute = router.currentRoute.value.fullPath;
            router.push({
              name: "login",
              query: { redirect: originalRoute },
            });
          });

        return;
      }

      $swal.fire({
        icon: "error",
        title: "Erro!",
        text: error,
      });
    }

    return Promise.reject(error);
  }
);

export default http;
